import React from "react";

import NoticeEnd from "components/admin/notice/NoticeEnd";
import NoticeList from "components/admin/notice/NoticeList";
import NoticeWrite from "components/admin/notice/NoticeWrite";
import NoticeModify from "components/admin/notice/NoticeModify";

import AccessUser from "components/admin/user/userlist/AccessUser";
import ManagedUser from "components/admin/user/userlist/ManagedUser";

import UsersBettingPage from "components/admin/betting/UsersBettingPage";
import UserBalanceManagement from "components/admin/user/UserBalanceManagement";
import UsersTransactionsPage from "components/admin/transactions/UsersTransactionsPage";
import UserRegistration from "components/admin/user/userregistration/UserRegistration";

import CasinoMenu from "components/gamemanagement/CasinoMenu";
import SportMenu from "components/gamemanagement/SportMenu";
import SlotMenu from "components/gamemanagement/SlotMenu";
import MiniMenu from "components/gamemanagement/MiniMenu";
import VirtualCurrency from "components/gamemanagement/VirtualCurrency";

import InquiryPage from "components/admin/inquiry/InquiryPage";
import InquiryWrite from "components/admin/inquiry/InquiryWrite";
import InquiryModify from "components/admin/inquiry/InquiryModify";

import ExchangePage from "components/admin/exchange/ExchangePage";

import Styles from "components/Styles";

import SiteConfig from "components/site/config/SiteConfig";
import SiteBonus from "components/site/bonus/SiteBonus";
import SiteAdmin from "components/site/admin/SiteAdmin";
import AgentList from "components/agent/list/AgentList";
import SiteOperational from "components/site/operational/SiteOperational";
import AgentBoardQnaList from "components/agent/qna/AgentBoardQnaList";
import JoinRequest from "components/admin/user/userlist/JoinRequest";
import SendMessage from "components/csmanagement/SendMessage";
import AgentChargeList from "components/agent/charge/AgentChargeList";
import UserQna from "components/csmanagement/UserQna";
import EventBoard from "components/csmanagement/EventBoard";
import NoticeBoard from "components/csmanagement/NoticeBoard";
import MenuCategory from "components/site/menucategory/MenuCategory";
import DepositManagement from "components/inout/DepositManagement";
import WithdrawManagement from "components/inout/WithdrawalManagement";
import PointChangeManagement from "components/inout/PointChangeManagement";
import PointManagement from "components/inout/PointManagement";
import UserCashStatistics from "components/statistics/user/cash/UserCashStatistics";
import UserPointStatistics from "components/statistics/user/point/UserPointStatistics";
import AgentStatistics from "components/statistics/agent/AgentStatistics";
import GameStatistics from "components/statistics/game/GameStatistics";
import { dbAuth } from "enum/db/useAuthEnum";
import Reference from "components/admin/user/userlist/Reference";
import AllUser from "components/admin/user/userlist/AllUser";
import BettingList from "components/admin/betting/BettingList";
import AgentChangeRequestList from "components/admin/user/userlist/AgentChangeRequestList";

const useAllRoutes = () => {
  const contentRoute = [

    
    { path: "/betting", component: BettingList },

    // 회원
    { path: "/u.all", component: Reference },
    { path: "", component: AllUser },
    { path: "/u.join", component: JoinRequest, type: dbAuth.type.A.value },
    { path: "/u.managed", component: ManagedUser },
    { path: "/u.access", component: AccessUser },
    { path: "/u.agent-chage-request", component: AgentChangeRequestList },

    // 공지사항
    { path: "/notice/:id", component: NoticeEnd },
    { path: "/notice/list", component: NoticeList },
    { path: "/notice/write", component: NoticeWrite },
    { path: "/notice/modify/:id", component: NoticeModify },

    // 유저배팅
    { path: "/userbalancemanagement", component: UserBalanceManagement },
    { path: "/usersbetting", component: UsersBettingPage },
    { path: "/transactions", component: UsersTransactionsPage },
    { path: "/sport", component: SportMenu },
    { path: "/casino", component: CasinoMenu },
    { path: "/slot", component: SlotMenu },
    { path: "/mini", component: MiniMenu },
    { path: "/virtual-currency", component: VirtualCurrency },
    // 문의사항
    { path: "/inquiry", component: InquiryPage },
    { path: "/inquiry/write/:inquiryNo", component: InquiryWrite },
    { path: "/inquiry/modify/:inquiryNo", component: InquiryModify },
    // 환전
    { path: "/exchange", component: ExchangePage },
    // 스타일북
    { path: "/styles", component: Styles },
    { path: "/user/registration", component: UserRegistration },
    // 회원통게
    { path: "/s.user-cash", component: UserCashStatistics },
    { path: "/s.user-point", component: UserPointStatistics },
    { path: "/s.agent", component: AgentStatistics },
    { path: "/s.game", component: GameStatistics },

    { path: "/site/config", component: SiteConfig, type: dbAuth.type.A.value },
    { path: "/site/bonus", component: SiteBonus, type: dbAuth.type.A.value },
    { path: "/site/admin", component: SiteAdmin, type: dbAuth.type.A.value },
    { path: "/site/operational", component: SiteOperational, type: dbAuth.type.A.value },
    { path: "/site/menucategory", component: MenuCategory, type: dbAuth.type.A.value },

    { path: "/agent/list", component: AgentList },
    { path: "/agent/charge", component: AgentChargeList },
    { path: "/agent/qna", component: AgentBoardQnaList },

    { path: "/csmanagement/message", component: SendMessage, type: dbAuth.type.A.value },
    { path: "/csmanagement/userqna", component: UserQna, type: dbAuth.type.A.value },
    { path: "/csmanagement/event", component: EventBoard, type: dbAuth.type.A.value },
    { path: "/csmanagement/notice", component: NoticeBoard, type: dbAuth.type.A.value },

    // 입/출 관리
    { path: "/deposit", component: DepositManagement, type: dbAuth.type.A.value },
    { path: "/withdrawal", component: WithdrawManagement, type: dbAuth.type.A.value },
    { path: "/point-change", component: PointChangeManagement, type: dbAuth.type.A.value },
    { path: "/point-paid", component: PointManagement, type: dbAuth.type.A.value },
  ];

  return {
    contentRoute,
  };
};

export default useAllRoutes;
