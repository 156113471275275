import { useEffect } from "react";
import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { InputForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import api from "interceptor/api";
import { Column, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import { dbUser } from "enum/db/useUserEnum";

import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { LineSection } from "style/useLayoutStyles";
import { AreaTitle, TextBox } from "style/useTextStyles";
import { MainBtn } from "style/useButtonStyles";
import { usePopup } from "contexts/useWindow";
import { t } from "i18next";

const UserCompSetting = ({ gameNo, userNo, type }) => {

  const useStated = useStateData();
  const { setLoading } = useCommonState();
  const { closePopup } = usePopup();

  useEffect(() => {
    getTableItem();
  }, []);

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = `/v1/game-management/comp-user/${gameNo}/${userNo}`;

    apiType(apiUrl, {
      params: {
        ...useForm.searchValue,
      },
    })
    .then((res) => {
      useForm.setFormValue({
        ...res.data.content,
      });
    })
    .catch((err) => {
      console.error(err);
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const Registration = () => {

    setLoading(true);

    const apiType = type === 'A' ? api.post : api.put;
    const apiUrl = `/v1/game-management/comp-user/${gameNo}`;

    apiType(apiUrl, {
      ...useForm.formValue,
    })
      .then(() => {
        closePopup();
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({});

  return (
    <>
      <Column className="area-box" $width="1200px">
        <SubTitleBox>
          <Row $gap="6px" $align="center">
            <AreaTitle>{t("user.user_info")}</AreaTitle>
          </Row>
        </SubTitleBox>
        <Row>
          <OptionBox $flex="1" $align="center">
            <TextBox $width="130px" $line="var(--l-sub)">
              {t("agent.agent")}
            </TextBox>
            <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
              {useForm.formValue.agent_code}
            </TextBox>
          </OptionBox>
          <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
          <OptionBox $flex="1" $align="center">
            <TextBox $width="130px" $line="var(--l-sub)">
              {t("user.user")}
            </TextBox>
            <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
              {`${useForm.formValue.nickname} (${useForm.formValue.user_no})`}
            </TextBox>
          </OptionBox>
        </Row>
        <Row>
          <OptionBox $flex="1" $align="center">
            <TextBox $width="130px" $line="var(--l-sub)">
              {t("user.user_lv")}
            </TextBox>
            <img
              src={process.env.REACT_APP_IMG_URL + useForm.formValue.img_uuid}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
              {`${useForm.formValue.level_name || "-"} (${ useForm.formValue.lv})`}
            </TextBox>
          </OptionBox>
          <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
          <OptionBox $flex="1" $align="center">
            <TextBox $width="130px" $line="var(--l-sub)">
              {t("user.search_user_state")}
            </TextBox>
            <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
              {dbUser.state1[useForm.formValue?.state1]?.label || "-"}
            </TextBox>
          </OptionBox>
        </Row>
        <Column $gap="12px" $width="100%" $pad="2vh 0 0 0">
          <Table className="table-box">
            <thead>
              <TableRow className="table-title">
                <TableHeader $width="100px">{t("userCommission.comp_type")}</TableHeader>
                <TableHeader $width="230px">{t("gameStatistics.casino")}</TableHeader>
                <TableHeader $width="230px">{t("gameStatistics.slot")}</TableHeader>
                <TableHeader $width="230px">{t("gameStatistics.mini_game")}</TableHeader>
                <TableHeader $width="230px">{t("gameStatistics.sports")}</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              <TableRow className="table-content">
                <TableDeps $height="42px" $tAlign="center">
                  {t("userCommission.rolling_comp")}
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="casino_rolling"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="slot_rolling"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="mini_game_rolling"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="sports_rolling"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
              </TableRow>
              <TableRow className="table-content">
                <TableDeps $height="42px" $tAlign="center">
                  {t("userCommission.losing_comp")}
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="casino_losing"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="slot_losing"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="mini_game_losing"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
                <TableDeps $pad="5px 12px" $height="42px">
                  <InputForm
                    maxWidth="240px"
                    type="text"
                    name="sports_losing"
                    height={"28px"}
                    placeholder="0%"
                    {...useForm}
                    {...useStated}
                  />
                </TableDeps>
              </TableRow>
            </tbody>
          </Table>
        </Column>
        <Row $pad="12px 0 12px 0" $gap="6px" $jus="center">
          <MainBtn $pad="6px 12px" onClick={Registration}>
            {type === 'A' ? t("gameManagement.registration_user_comp") : t("gameManagement.update_user_comp")}
          </MainBtn>
        </Row>
      </Column>
    </>
  );
};

export default UserCompSetting;
