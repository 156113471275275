import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import CalendarFilter from "components/common/CalendarFilter";
import { RadioForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { periodType, useFiveCountPerPage } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import { useAlert } from "contexts/useWindow";
import { dbUserStatisticsGameDay } from "enum/db/useUserEnum";
import { periodTypeEnum } from "enum/statistics/useTableEnum";
import { t } from "i18next";
import api from "interceptor/api";
import { isEmpty } from "lodash";
import { useState } from "react";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import Pagination from "utils/Pagination";
import { yyyymmToDateFormat, yyyymmddToDateFormat } from "utils/date/DateUtil";
import { formatNumber, sortDateObjectKeys } from "utils/statistics/StatisticsUtil";

const GameStatistics = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();
  const { openAlert } = useAlert();
  const [period, setPeriod] = useState(periodTypeEnum.D.value);

  const getTableItem = () => {
    if (useSearch.searchValue.search_agent_val != "" && useSearch.searchValue.search_agent_category == "") {
      openAlert({
        title: t("header.alert_title"),
        message: t("agent.check_search_type"),
      });
      return
    }

    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/statistics/game/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setPeriod(useSearch.formValue.period);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        useTG.setData([]);
        if (err.response.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        } else if (err.response?.status === 501) {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_name + t("agent.exist_agent_name"),
          });
        } else if (err.response?.status === 502) {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_val + t("agent.exist_agent_code"),
          });
        } else if (err.response?.status === 503) {
          openAlert({
            title: t("header.alert_title"),
            message: useSearch.searchValue.search_agent_val + t("agent.exist_agent_no"),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });;
  };

  const useTG = useTableData(true, "5");
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      period: periodTypeEnum.D.value,
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  const groupedData = useTG.data
    ? useTG.data.reduce((acc, row) => {

      // 날짜 엔트리 초기화
      if (!acc[row.yyyymmdd]) {
        acc[row.yyyymmdd] = {};
      }

      // 결제 유형 엔트리 초기화
      if (!acc[row.yyyymmdd][row.m_kind]) {
        acc[row.yyyymmdd][row.m_kind] = {
          bet_money: 0,
          bet_count: 0,
          win_money: 0,
          win_count: 0
        };
      }

      const paymentTypeEntry = acc[row.yyyymmdd][row.m_kind];

      // 타입에 따라 금액과 카운트 업데이트
      paymentTypeEntry.bet_money += parseInt(row.bet_money) || 0;
      paymentTypeEntry.bet_count += parseInt(row.bet_count) || 0;
      paymentTypeEntry.win_money += parseInt(row.win_money) || 0;
      paymentTypeEntry.win_count += parseInt(row.win_count) || 0;

      return acc;
    }, {})
    : {};

  const sortedDates = Object.keys(groupedData).sort((a, b) => b - a);

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.stats_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.game_states")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap='4px'>
              <FilterTitle>{t("statistics.select_method_period")}</FilterTitle>
              <Row $align='center' $gap='12px' $height='34px'>
                <RadioForm name='period' options={periodType} {...useSearch} />
                <CalendarFilter endName='end_date' startName='start_date' {...useSearch} end />
              </Row>
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="flex-end" $width="100%" $align="center" $pad="20px 0 14px">
          <SelectForm
            name="countPerPage"
            placeholder={useFiveCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useFiveCountPerPage}
            formValue={useFiveCountPerPage.value}
          />
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="130px">{t("statistics.date")}</TableHeader>
                  <TableHeader>{t("statistics.category")}</TableHeader>
                  <TableHeader>{t("gameStatistics.bet_count")}</TableHeader>
                  <TableHeader>{t("gameStatistics.bet_money")}</TableHeader>
                  <TableHeader>{t("gameStatistics.win_count")}</TableHeader>
                  <TableHeader>{t("gameStatistics.win_money")}</TableHeader>
                  <TableHeader>{t("gameStatistics.net_profit")}</TableHeader>
                  <TableHeader>{t("gameStatistics.avg_bet")}</TableHeader>
                  <TableHeader>{t("gameStatistics.avg_win")}</TableHeader>
                </tr>
              </thead>
              {sortedDates.map((date, index) => {
                const paymentTypes = Object.keys(groupedData[date]);
                return (
                  <tbody key={date}>
                    {paymentTypes.map((payment_type, ptIndex) => {
                      const number = useTG.pagingInfo.offset - index;
                      const item = groupedData[date][payment_type];
                      const net_profit = item.bet_money - item.win_money;
                      const netProfitClassName = net_profit > 0 ? "complete" : net_profit < 0 ? "warn" : "";
                      const avg_bet = item.bet_count ? item.bet_money / item.bet_count : 0;
                      const avg_win = item.win_count ? item.win_money / item.win_count : 0;
                      const rowSpan = ptIndex === 0 ? paymentTypes.length : 1;

                      return (
                        <TableRow key={`${date}-${payment_type}-${ptIndex}`} className="table-content">
                          {ptIndex === 0 && (
                            <>
                              <TableDeps $width="60px" rowSpan={rowSpan}>{number}</TableDeps>
                              <TableDeps $width="130px" rowSpan={rowSpan}>
                                {period === periodTypeEnum.D.value
                                  ? yyyymmddToDateFormat(date) || "-"
                                  : period === periodTypeEnum.M.value
                                    ? yyyymmToDateFormat(date) || "-"
                                    : "-"}
                              </TableDeps>
                            </>
                          )}
                          <TableDeps $tAlign="left">{dbUserStatisticsGameDay.payment_type[payment_type]?.label || "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.bet_count ? `${formatNumber(item.bet_count)}` : "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.bet_money ? `\\${formatNumber(item.bet_money)}` : "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.win_count ? `${formatNumber(item.win_count)}` : "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.win_money ? `\\${formatNumber(item.win_money)}` : "-"}</TableDeps>
                          <TableDeps $tAlign="right">
                            <TextBox className={netProfitClassName}>
                              {net_profit ? net_profit.toLocaleString(undefined, {
                                style: "currency",
                                currency: "KRW",
                                signDisplay: "exceptZero",
                              }) : "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps $tAlign="right">{avg_bet ? `\\${formatNumber(avg_bet)}` : "-"}</TableDeps>
                          <TableDeps $tAlign="right">{avg_win ? `\\${formatNumber(avg_win)}` : "-"}</TableDeps>
                        </TableRow>
                      );
                    })}
                  </tbody>
                );
              })}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row
          $gap="8px"
          $pad="14px 0 0"
          $align="center"
          $jus="flex-end"
          $width="100%"
        >
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && (
              <Pagination {...useTG} />
            )}
          </PositionBox>
          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useFiveCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useFiveCountPerPage}
            formValue={useFiveCountPerPage.value}
          />
        </Row>
      </Column >
    </>
  );
};

export default GameStatistics;
