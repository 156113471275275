import React from "react";
// 공용 팝업
// style import
import { IconLink } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { AreaHeader, Column, Row } from "style/custom/useCustomLayout";
import { t } from "i18next";

const SportMenu = () => {
  // const useTG = useTableData(true);
  // const useStated = useStateData();

  // const { openPopup } = usePopup();
  // const { openAlert } = useAlert();
  // const { setLoading } = useCommonState();

  // const confirmUseYn = (use_yn) => {
  //   const checkedGameTitle = useTG.data
  //     .filter((item) => item.isChecked)
  //     .map((item) => item.title)
  //     .join(", ");
  //   if (checkedGameTitle === "") {
  //     openAlert({
  //       title: t("gameManagement.select_game"),
  //       message: t("gameManagement.no_selected_game"),
  //       iconURL: true,
  //     });

  //     return;
  //   }
  //   putUseYn(use_yn);
  // };
  
  // const showPopup = (item) => {
  //   openPopup({
  //     title: t("gameManagement.game_management", { title: item?.title }),
  //     content: GameMngPopupForm,
  //     props: {
  //       item,
  //       getGameList,
  //     },
  //   });
  // };

  // const getGameList = () => {
  //   setLoading(true);
  //   api
  //     .get("/v1/game-management", {
  //       params: {
  //         ...useSearch.searchValue,
  //         ...useTG.extendData.pagingData,
  //       },
  //     })
  //     .then((res) => {
  //       useTG.setData(res.data.content);
  //       if (res.data.pageHelperDto) {
  //         useTG.setPagingInfo(res.data.pageHelperDto);
  //       }
  //       setLoading(false);
  //       useStated.setSuccessData([res.data.content]);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       if (error.response?.status === 400) {
  //         useStated.setErrorData(error.response.data.errors);
  //       }
  //       console.error(error);
  //     });
  // };

  // const putUseYn = (use_yn) => {
  //   const checkedList = useTG.data.filter((item) => item.isChecked);
  //   api
  //     .put(`/v1/game-management/use-yn`, { no_list: checkedList.map((item) => item.no), use_yn: use_yn })
  //     .then((res) => {
  //       getGameList();
  //     })
  //     .catch((error) => {
  //       openAlert({
  //         title: t("gameManagement.change_use_status"),
  //         message: t("gameManagement.change_use_status_failed"),
  //         iconURL: true,
  //       });
  //       console.error(error);
  //     });
  // };

  // const useSearch = useFormData(
  //   {
  //     game_type: "C",
  //     keyword: "",
  //     search_state: searchStateEnum.title.value,
  //     search_use_yn: activeStateEnum.all.value,
  //   },
  //   getGameList,
  //   useTG
  // );

  return (
    <>
      <AreaHeader>
        <SubText>{t("gameManagement.game_management")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("gameManagement.mini")}</AreaTitle>
      </AreaHeader>

      <Column>
        <Row $pad="16px 0 0 0">
          <TextBox $color="var(--c-black-600)" $font="var(--f-subB)" $size="24px">
            {t("content.preparing")}
          </TextBox>
        </Row>
      </Column>
    </>
  );
};

export default SportMenu;
